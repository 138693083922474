import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
import Notiflix from "notiflix";
//import React, { useEffect } from 'react';

const Contact = () => {
  useDocTitle("ZelT | IT Services");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    document.getElementById("submitBtn").disabled = true;
    document.getElementById("submitBtn").innerHTML = "Loading...";
    let fData = new FormData();
    fData.append("first_name", firstName);
    fData.append("last_name", lastName);
    fData.append("email", email);
    fData.append("phone_number", phone);
    fData.append("message", message);

    axios({
      method: "post",
      url: process.env.REACT_APP_CONTACT_API,
      data: fData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "send message";
        clearInput();
        //handle success
        Notiflix.Report.success("Success", response.data.message, "Okay");
      })
      .catch(function (error) {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "send message";
        //handle error
        const { response } = error;
        if (response.status === 500) {
          Notiflix.Report.failure(
            "An error occurred",
            response.data.message,
            "Okay"
          );
        }
        if (response.data.errors !== null) {
          setErrors(response.data.errors);
        }
      });
  };

  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div id="contact" className="mt-8 w-full bg-white py-12 lg:py-24 ">
        <div
          className="flex justify-center items-center my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <form onSubmit={sendEmail}>
            <div className="flex justify-center items-center"></div>
            <div className="bg-black w-full p-8 my-12 md:px-8 lg:w-12/12 lg:pl-20 lg:pr-400 mr-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center text-blue-900 uppercase text-4xl">
                  Send us a message or Open ticket
                </h1>
              </div>
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <div className="w-center lg:-mt-200 lg:w-2/6 px-30 py-6 ml-auto bg-blue-900 rounded-2xl">
                  <div className="flex flex-col text-white">
                    <div className="flex my-4 w-2/3 lg:w-full">
                      <div className="flex flex-col">
                        <i className="fas fa-map-marker-alt pt-2 pr-2" />
                      </div>
                      <div className="flex flex-col">
                        <h2 className="text-2xl">Office Address</h2>
                        <p className="text-gray-400">
                          P.O. Box 38732, San Diego, Ca, 92198
                        </p>
                      </div>
                    </div>

                    <div className="flex my-4 w-2/3 lg:w-1/2">
                      <div className="flex flex-col">
                        <i className="fas fa-phone-alt pt-2 pr-2" />
                      </div>

                      <div className="flex flex-col">
                        <h2 className="text-2xl">Call Us</h2>
                        <p className="text-gray-400">Tel: 858-215-1048</p>

                        <div className="mt-5">
                          <h2 className="text-2xl">Send an E-mail</h2>
                          <p className="text-gray-400">
                            support@zeltechnology.org
                          </p>
                        </div>

                        <div className="mt-5">
                          <h2 className="text-2xl">Open A Support Ticket 'COMING SOON'</h2>
                          <a
                            href="https://zeltechnology.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-gray-400"
                          >
                            Open a Ticket
                          </a>
                        </div>


                        <div className="mt-5">
                          <h2 className="text-2xl">Donate</h2>
                          <form onSubmit={sendEmail}>
                          {/* Adding my button with a customized logo here */}
                          <div className="flex justify-start mt-6">
                          <a href="https://square.link/u/hJzYmg7H" className="flex items-center bg-blue-900 text-white px-4 py-2 rounded-full hover:bg-blue-700">
                          {/*<img src={process.env.PUBLIC_URL + '../images/donate1.png'} alt="Custom Logo" className="w-12.2 h-12.2 mr-1" /> {/* Adjust width and height*/}
                          <img src={process.env.PUBLIC_URL + '../images/donate1.png'} alt="Custom Logo" className="max-w-full h-auto mr-1" style={{ maxWidth: '125px', maxHeight: '125px', objectFit: 'contain' }} />
                          
                          </a>
                          </div>
                          </form>
                        </div>

                      </div>
                    </div>

                    <div className="flex my-4 w-2/3 lg:w-1/2">
                      <a
                        href="https://www.facebook.com/zeltechnology/"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="fill-current font-black hover:animate-pulse"
                        >
                          <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/zeltechnology-inc-"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="fill-current font-black hover:animate-pulse"
                        >
                          <circle cx="4.983" cy="5.009" r="2.188"></circle>
                          <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;