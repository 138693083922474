import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                           {/* <h3 className="text-3xl  text-blue-900 font-bold">Our staff is highly trained and knowledgeable in all things PC, Mac, Linux, Windows, and more! Whatever your IT or San Diego computer repair needs are, you can be sure that we will provide the best solution possible.</h3> */}
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>  ZelTechnology is a non-profit organization that provides FREE tech services to you. Why do we do this? Let me share a story. In my previous role at a corporate company, I encountered two clients who left a profound impact on me. One was a diligent student who relied on his computer for his studies and work. The other was a veteran, bewildered by his new, modern computer, unsure how to even turn it on <br/>

I managed to repair the student’s computer, but he couldn't afford the hefty fees and had to wait until his next paycheck to retrieve it. The veteran, on the other hand, had no one to turn to for help, especially when it came to safeguarding his personal data. These experiences revealed to me a harsh truth: the vulnerable often have no advocates.<br/>

Determined to make a difference, we shifted our approach. We used to charge for our services, but now we're seeking donations to cover our costs. Sometimes, all it takes is a listening ear or a bit of advice to transform someone's day. IT services shouldn't be a luxury; they should be free and accessible to all.<br/>

Our technicians are not just experts; they are compassionate individuals with extensive experience working with community members, businesses, local non-profits, students, active military personnel, veterans, and senior citizens. At ZelTechnology, we believe in the power of technology to bridge gaps and create opportunities for everyone.<br/>
</p>
                            </div>
                            
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Our goal is to provide exceptional care and resolve your tech issues at an affordable cost. Why pay an arm and a leg for essentials? We value building relationships with our community, and going the extra mile is an integral part of the service packages we offer.</p>
                            </div>
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;