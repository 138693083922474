import React from 'react';
import Cta from './Cta';
import Footer from './Footer';
import Hero from './Hero';
import Intro from './Intro';
import Services from './Services';

const Home = () => {
    return (
        <>
            <Hero />
            {/* <UpdateBanner /> */}
            <Intro />
            <Services />          
            <Cta/>
            <Footer />
        </>
    )
}

/*
const UpdateBanner = () => {
    console.log("UpdateBanner component rendered");

    return (
        <div style={bannerStyles.container}>
            <p style={bannerStyles.text}>
                <strong>Exciting Update Coming Soon!</strong><br />
                Stay tuned for an amazing new feature that will enhance your experience.
            </p>
        </div>
    );
}

const bannerStyles = {
    container: {
        backgroundColor: '#fffae6',
        color: '#333',
        padding: '20px',
        textAlign: 'center',
        borderRadius: '10px',
        margin: '20px auto',
        width: '80%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        animation: 'fadeIn 2s ease-in-out',
    },
    text: {
        fontSize: '1.2em',
        lineHeight: '1.5',
    }
};
*/

export default Home;
